import React, { useState } from "react";
import { useFlexSearch } from "react-use-flexsearch";
import { graphql, useStaticQuery } from "gatsby";
import ProductGrid from "./ProductGrid";

const SearchBar = () => {

	const localSearchData = useStaticQuery(graphql`
        query LocalSearchData {
            localSearchProducts {
                index
                store
            }
        }
    `).localSearchProducts;

	const [query, setQuery] = useState("");

	const results = useFlexSearch(query, localSearchData.index, localSearchData.store);

	const updateSearchQuery = (e) => {
		setQuery(e.target.value);
	};

	return (
		<div>
			<form>
				<input
					className="w-full p-2 border-2 border-gray-200 rounded-md mb-10"
					onChange={updateSearchQuery}
					placeholder="Ingrese nombre o código del producto..."
					autoFocus
				>
				</input>
			</form>
			<ProductGrid products={ results } />
		</div>
	);
};

export default SearchBar;