import React from "react";
import Layout from "../components/Layout";
import SearchBar from "../components/SearchBar";

const Busqueda = () => {

	return (
		<Layout hasSlider={false}>
			<div className="h-fit mr-4 pb-6 pl-12">
				<p className="font-semibold text-xl">Buscar producto</p>
				<hr className="w-full mt-2 mb-8 border-gray-200 border-x-2"></hr>
				<SearchBar />
			</div>
		</Layout>
	);
};

export default Busqueda;